var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ComponentUploadDropdown"),
      _c("ComponentListDropdown"),
      _c("ComponentListDropdownSalesperson"),
      _c("ComponentListDropdownSalesfunnel"),
      _c("ComponentListDropdownLead"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }